<template>
  <v-list-item v-bind="$attrs" tag="label" link>
    <template #prepend>
      <v-avatar v-if="!thumbnailsBig && thumbnail" variant="elevated" :image="thumbnail" />
      <v-avatar v-else variant="elevated" :color="extensionColor(fileData.extension)">
        {{ fileData.extension.toUpperCase() }}
      </v-avatar>
    </template>

    <template #title>
      <div class="d-flex align-baseline" style="gap: 8px;">
        <v-tooltip v-if="fileData.general" location="top">
          <template #activator="{ props }"><v-icon size="14px" v-bind="props" icon="attach_file"/></template>
          <template #default>{{ $t("linkedFile") }}</template>
        </v-tooltip>

        <v-tooltip v-if="fileData.private" location="top">
          <template #activator="{ props }"><v-icon size="14px" v-bind="props" icon="lock"/></template>
          <template #default>{{ $t("foreclosed") }}</template>
        </v-tooltip>

        <span class="ellipsis">{{ fileData.description }}</span>
      </div>
    </template>

    <template #subtitle>
      <div class="d-flex" style="gap: 0.5rem;">
        <div>{{ $d(new Date(fileData.createdAt!)) }}</div>

        <v-divider vertical />
        <div>{{ prettyBytes(fileData.size ?? 0) }}</div>

        <template v-if="fileData.uploadedBy">
          <v-divider vertical />
          <div>{{ fileData.uploadedBy.user.description }}</div>
          <v-divider vertical />
          <div>{{ fileData.uploadedBy.organization.description }}</div>
        </template>

        <template v-if="fileData.remark">
          <v-divider vertical />
          <div>{{ fileData.remark }}</div>
        </template>
      </div>
    </template>

    <template #append>
      <v-card-actions class="pa-0 d-flex" style="min-height: 40px;">
        <slot name="actions"/>

        <v-btn v-if="!isSuperAdmin && (fileData.general || fileData.private)" :id="`action-${fileData._id}`" icon="visibility" target="_blank" :href="`/files/${fileData._id}`" />

        <file-actions
          v-else
          v-model="fileData"
          style="order: 1;"
          :hide-delete="hideDelete"
          :hide-download="hideDownload"
          :hide-rename="hideRename"
          :hide-remark="hideRemark"
          :hide-rotate="hideRotate"
          :hide-visibility="hideVisibility"
          @delete="() => emit('delete')"
        />

        <v-menu v-if="movable">
          <template #activator="{ props }">
            <v-btn v-bind="props" icon style="order: 0;" size="small">
              <v-icon size="large" icon="swap_vert" />
            </v-btn>
          </template>

          <template #default>
            <v-list>
              <v-list-item :title="$t('file.moveToStart')" prepend-icon="vertical_align_top" @click="emit('moveToStart')" />
              <v-list-item :title="$t('file.moveUp')" prepend-icon="move_up" @click="emit('moveUp')" />
              <v-list-item :title="$t('file.moveDown')" prepend-icon="move_down" @click="emit('moveDown')" />
              <v-list-item :title="$t('file.moveToEnd')" prepend-icon="vertical_align_bottom" @click="emit('moveToEnd')" />
            </v-list>
          </template>
        </v-menu>
      </v-card-actions>
    </template>
  </v-list-item>

  <v-img v-if="thumbnailsBig && fileData.type === 'IMAGE'" :src="thumbnail ?? undefined" width="100%" height="200px" contain alt="thumbnail" />
</template>

<script setup lang="ts">
import prettyBytes from "pretty-bytes"
import { extensionColor } from "~~/utils/file-color"

const fileStore = useFileStore()
const authStore = useAuthStore()
const { isSuperAdmin } = storeToRefs(authStore)

const { thumbnailsBig } = useUserPreferences()

const fileData = defineModel<FileData>({ required: true })

const emit = defineEmits<{
  (e: "delete" | "moveToStart" | "moveUp" | "moveDown" | "moveToEnd"): void
}>()

const props = defineProps<{
  movable?: boolean,
  hideDownload?: boolean
  hideDelete?: boolean
  hideRename?: boolean
  hideRemark?: boolean
  hideRotate?: boolean
  hideVisibility?: boolean
}>()
const { movable, hideDownload, hideDelete, hideRename, hideRemark, hideRotate, hideVisibility } = toRefs(props)

const thumbnail = ref<string>()

watch(fileData, async () => {
  if (fileData.value.type === "IMAGE" && thumbnail.value === undefined) {
    thumbnail.value = await fileStore.getFileUrl(fileData.value._id, "/no-image.png", true)
  }
}, { immediate: true, deep: true })
</script>
