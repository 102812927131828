<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn :id="id" v-bind="props" icon variant="text" :size="size ?? 'small'" :aria-label="$t('more')">
        <v-icon icon="more_vert" :size="iconSize ?? 'large'"/>
      </v-btn>
    </template>

    <template #default>
      <v-list>
        <v-list-item :title="$t('viewInBrowser')" prepend-icon="visibility" target="_blank" :href="`/files/${fileData._id}`"/>

        <v-list-item v-if="!hideDownload" :title="$t('download')" prepend-icon="download" @click="downloadImage(fileData._id, fileData.description, fileData.extension)"/>

        <v-divider class="my-2" />

        <v-dialog width="600" :disabled="!hasScope(fileData.organization, AuthScope.CAN_UPDATE_FILES)">
          <template #activator="{ props }">
            <v-list-item v-if="!hideRename" v-bind="props" prepend-icon="edit" :title="$t('rename')" :disabled="!hasScope(fileData.organization, AuthScope.CAN_UPDATE_FILES)" />
          </template>

          <template #default="{ isActive }">
            <v-card :title="$t('rename')">
              <rename-file-form :file-data="fileData" @ok="(description: string) => { fileData = { ...fileData, description }; isActive.value = false }" @cancel="() => isActive.value = false" />
            </v-card>
          </template>
        </v-dialog>

        <v-dialog width="600" :disabled="!hasScope(fileData.organization, AuthScope.CAN_UPDATE_FILES)">
          <template #activator="{ props }">
            <v-list-item v-if="!hideRemark" v-bind="props" prepend-icon="edit_note" :title="fileData.remark ? $t('editRemark') : $t('addRemark')" :disabled="!hasScope(fileData.organization, AuthScope.CAN_UPDATE_FILES)" />
          </template>

          <template #default="{ isActive }">
            <v-card :title="fileData.remark ? $t('editRemark') : $t('addRemark')">
              <file-remark-form :file-data="fileData" @ok="(remark: string) => { fileData = { ...fileData, remark }; isActive.value = false }" @cancel="() => isActive.value = false" />
            </v-card>
          </template>
        </v-dialog>

        <v-list-item v-if="fileData.type === 'IMAGE' && !hideRotate" :title="$t('rotateImage')" prepend-icon="rotate_right" @click="rotateImage()"/>

        <v-dialog width="600">
          <template #activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="info_outline" :title="$t('file.details.title')" />
          </template>

          <template #default="{ isActive }">
            <v-card>
              <template #title>{{ $t('file.details.title') }}</template>

              <file-properties density="comfortable" :file-data="fileData" />

              <v-card-actions class="justify-end">
                <v-btn color="primary" @click="isActive.value = false">{{ $t('close') }}</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-divider v-if="!hideVisibility || !hideDelete" class="my-2" />

        <template v-if="!hideVisibility">
          <v-list-item v-if="!fileData.private" :disabled="!hasScope(fileData.organization, AuthScope.CAN_HANDLE_CLASSIFIED_FILES)" prepend-icon="lock" @click="() => updateFileVisibility(true)">{{ $t('file.visibility.setPrivate') }}</v-list-item>
          <v-list-item v-else prepend-icon="lock_open" :disabled="!hasScope(fileData.organization, AuthScope.CAN_HANDLE_CLASSIFIED_FILES)" @click="() => updateFileVisibility(false)">{{ $t('file.visibility.setPublic') }}</v-list-item>
        </template>

        <v-dialog v-if="!hideDelete" width="600" :disabled="!hasScope(fileData.organization, AuthScope.CAN_DELETE_FILES)">
          <template #activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="delete" :title="$t('delete')" :disabled="!hasScope(fileData.organization, AuthScope.CAN_DELETE_FILES)" />
          </template>

          <template #default="{ isActive }">
            <v-card :title="$t('assets.details.removeFileDialog.title')" :subtitle="$t('assets.details.removeFileDialog.message')">
              <v-card-actions class="justify-end">
                <v-btn color="primary" @click="isActive.value = false">{{ $t('cancel') }}</v-btn>
                <v-btn color="error" @click="() => { isActive.value = false; emit('delete') }">{{ $t('delete') }}</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-list>
    </template>
  </v-menu>
</template>

<script setup lang="ts">
import { AuthScope } from "~~/stores/auth.store"

const fileData = defineModel<FileData>({ required: true })

const emit = defineEmits<{
  (e: "delete"): void
}>()

const props = defineProps<{
  id?: string,
  hideDownload?: boolean
  hideRename?: boolean
  hideRemark?: boolean
  hideDelete?: boolean
  hideRotate?: boolean
  hideVisibility?: boolean
  size?: "x-small" | "small" | "default" | "large" | "x-large"
  iconSize?: "x-small" | "small" | "default" | "large" | "x-large"
}>()
const { id, hideDownload, hideRename, hideRemark, hideDelete, hideRotate, hideVisibility, size, iconSize } = toRefs(props)

const $i18n = useI18n()

const mainStore = useMainStore()
const { hasScope } = useAuthStore()
const fileStore = useFileStore()

const downloadImage = useDownloadFile(mainStore.notify, $i18n, fileStore)

const rotateImage = () => {
  const currentAngle = fileData.value.angle ?? 0
  const newAngle = (currentAngle + 90) % 360

  useExplicitSave(mainStore.notify, $i18n, async () => {
    fileData.value = await fileStore.updateFile(fileData.value._id, { angle: newAngle })
  }, "update")
}

const updateFileVisibility = async (setPrivate = true) => {
  useExplicitSave(mainStore.notify, $i18n, async () => {
    fileData.value = await fileStore.updateFile(fileData.value._id, { private: setPrivate })
  }, "update")
}
</script>